export const getHttpStatusCodeColor = (statusCode: number) => {
  if (statusCode >= 100 && statusCode <= 199) {
    return 'gray';
  } else if (statusCode >= 200 && statusCode <= 299) {
    return 'green';
  } else if (statusCode >= 300 && statusCode <= 399) {
    return 'yellow';
  } else if (statusCode >= 400 && statusCode <= 499) {
    return 'orange';
  } else if (statusCode >= 500 && statusCode <= 599) {
    return 'red';
  }
};
