import { RedirectionChecker } from '@edt-monorepo/domaintrekker/features/redirection-checker';
import { FullBleed } from '@edt-monorepo/shared/ui';
import { Stack, Title } from '@mantine/core';

export function HomePage() {
  return (
    <FullBleed>
      <Stack>
        <Title order={1}>Free Redirection Checker</Title>
        <RedirectionChecker />
      </Stack>
    </FullBleed>
  );
}

export default HomePage;
