export const getHttpMeaning = (statusCode: number) => {
  if (statusCode >= 100 && statusCode <= 199) {
    return 'This is an informational response';
  } else if (statusCode >= 200 && statusCode <= 299) {
    if (statusCode === 200) {
      return 'This is the most common successful response and what you want for your pages most of the time';
    } else {
      return 'This is a successful response';
    }
  } else if (statusCode >= 300 && statusCode <= 399) {
    return 'This is a redirection message';
  } else if (statusCode >= 400 && statusCode <= 499) {
    return 'This is a client error response';
  } else if (statusCode >= 500 && statusCode <= 599) {
    return 'This is a server error response';
  }
};
