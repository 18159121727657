import { Hint, Link } from '@edt-monorepo/shared/ui';
import { Fragment, useMemo } from 'react';
import { ActionIcon, Badge, Group, Stack, Table, Text } from '@mantine/core';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  IconCircleCheck,
  IconCircleX,
  IconExclamationCircle,
  IconExternalLink,
  IconLock,
  IconLockOpen,
  IconMinus,
  IconPlus,
} from '@tabler/icons-react';
import { Result } from '@edt-monorepo/domaintrekker/schema';
import { getHttpStatusCodeColor } from '../../helpers/get-http-status-color';
import { getHttpMeaning } from '../../helpers/get-http-meaning';

type RedirectionsTableProps = {
  results: Result[];
};

export const RedirectionsTable = ({ results }: RedirectionsTableProps) => {
  const columnHelper = createColumnHelper<Result>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('statusCode', {
        header: '',
        size: 50,
        cell: (cellContext) => {
          if (!cellContext.row.original.statusCode) {
            return null;
          }

          return (
            <Hint
              label={
                cellContext.row.getIsExpanded()
                  ? 'Hide headers'
                  : 'Show headers'
              }
            >
              <ActionIcon
                variant="light"
                onClick={() => cellContext.row.toggleExpanded()}
              >
                {cellContext.row.getIsExpanded() ? (
                  <IconMinus size={18} />
                ) : (
                  <IconPlus size={18} />
                )}
              </ActionIcon>
            </Hint>
          );
        },
      }),
      columnHelper.accessor('statusCode', {
        header: 'Status',
        size: 40,
        cell: (cellContext) => {
          const value = cellContext.getValue();
          if (value !== undefined) {
            return (
              <Hint
                label={
                  <Stack gap="xs">
                    <Badge color={getHttpStatusCodeColor(value)}>
                      {cellContext.getValue()}
                    </Badge>
                    <Text>{getHttpMeaning(value)}</Text>
                  </Stack>
                }
              >
                <Badge color={getHttpStatusCodeColor(value)}>
                  {cellContext.getValue()}
                </Badge>
              </Hint>
            );
          }
          return <IconExclamationCircle size={18} color="red" />;
        },
      }),
      columnHelper.accessor('scheme', {
        header: 'Scheme',
        size: 40,
        cell: (cellContext) => {
          const value = cellContext.getValue();
          const statusCode = cellContext.row.original.statusCode;

          if (statusCode === undefined) {
            return <IconExclamationCircle size={18} color="red" />;
          }

          if (value === 'https') {
            return (
              <Hint
                label={
                  <Group gap="xs" wrap="nowrap">
                    Data is encrypted with https{' '}
                    <IconCircleCheck size={16} color="green" />
                  </Group>
                }
              >
                <Group gap="xs" wrap="nowrap">
                  <IconLock size={18} color="green" />
                  https
                </Group>
              </Hint>
            );
          }
          return (
            <Hint
              label={
                <Group gap="xs" wrap="nowrap">
                  Data is not encrypted with https{' '}
                  <IconCircleX size={16} color="red" />
                </Group>
              }
            >
              <Group gap="xs" wrap="nowrap">
                <IconLockOpen size={18} color="red" />
                http
              </Group>
            </Hint>
          );
        },
      }),
      columnHelper.accessor('url', {
        header: 'URL',
        cell: (cellContext) => (
          <Link
            href={cellContext.getValue()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Group gap="xs" wrap="nowrap">
              <Text>{cellContext.getValue()}</Text>
              <IconExternalLink size={18} style={{ flex: 'none' }} />
            </Group>
          </Link>
        ),
      }),
      columnHelper.accessor('description', {
        header: 'Description',
        size: 100,
        cell: (cellContext) => cellContext.getValue(),
      }),
    ],
    [columnHelper]
  );

  const table = useReactTable({
    data: results,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table.ScrollContainer minWidth={600}>
      <Table>
        <Table.Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.Th
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Table.Th>
              ))}
            </Table.Tr>
          ))}
        </Table.Thead>
        <Table.Tbody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <Table.Tr>
                {row.getVisibleCells().map((cell) => (
                  <Table.Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Td>
                ))}
              </Table.Tr>
              {row.original.headers && row.getIsExpanded() && (
                <Table.Tr>
                  <Table.Td colSpan={columns.length}>
                    {Object.entries(row.original.headers).map(
                      ([key, value]) => (
                        <Group key={`${row.id}-${key}`} gap="xs">
                          <Text fw={700}>{key}:</Text>
                          <Text>{value}</Text>
                        </Group>
                      )
                    )}
                  </Table.Td>
                </Table.Tr>
              )}
            </Fragment>
          ))}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};
