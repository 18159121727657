import { Form, Paper, TextInput } from '@edt-monorepo/shared/ui';
import { formSchema } from '../../form-schema/form-schema';
import { Button } from '@mantine/core';
import { RedirectionsTable } from '../RedirectionsTable';
import { useRedirectionChecker } from '../../hooks/useRedirectionChecker';

export const RedirectionChecker = () => {
  const { onSubmit, error, loading, results } = useRedirectionChecker();

  return (
    <>
      <Form
        onSubmit={onSubmit}
        schema={formSchema}
        error={error}
        loading={loading}
        defaultValues={{ url: 'https://' }}
      >
        <Paper>
          <TextInput
            name="url"
            label="URL"
            placeholder="https://website.com"
            description={
              <>
                Remember to include <strong>http://</strong> or{' '}
                <strong>https://</strong>
              </>
            }
          />
        </Paper>
        <Button type="submit">Check</Button>
      </Form>
      {!error && !loading && results.length !== 0 && (
        <RedirectionsTable results={results} />
      )}
    </>
  );
};
