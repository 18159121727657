import * as z from 'zod';

const baseUrl = z
  .string({
    required_error: 'Please provide a url',
    invalid_type_error: 'Please provide a url',
  })
  .url();

export const formSchema = z.object({
  url: z.union(
    [
      baseUrl.startsWith('http://', 'Url must start with https:// or http://'),
      baseUrl.startsWith('https://', 'Url must start with https:// or http://'),
    ],
    {
      invalid_type_error: 'Url must start with https:// or http://',
      required_error: 'Url must start with https:// or http://',
    }
  ),
});

export type FormSchema = z.infer<typeof formSchema>;
