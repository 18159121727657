import { useBaseForm } from '@edt-monorepo/shared/ui';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useState } from 'react';
import { Result } from '@edt-monorepo/domaintrekker/schema';
import { FormSchema } from '../form-schema/form-schema';

export const useRedirectionChecker = () => {
  const worker = useWorker(
    createWorkerFactory(() => import('../worker/worker'))
  );
  const [results, setResults] = useState<Result[]>([]);

  const { loading, error, onSubmit } = useBaseForm({
    onSubmit: async (formValues: FormSchema) => {
      const data = await worker.handle(formValues.url);
      setResults(data);
    },
  });

  return { loading, error, onSubmit, results };
};
